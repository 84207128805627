<template>
  <Modal closePosition="hidden">
    <div class="p-6 root">
      <h2 class="font-h font-semibold text-primary text-2xl">
        Exporting your audio files...
      </h2>

      <div class="mt-1 text-sm text-primary">
        You will receive an email when its ready.
      </div>

      <div class="mt-3 text-sm text-primary">
        You can also find all your exported files under the “Exports” tab
      </div>

      <img class="mt-4" src="../../assets/images/exportMessage/1.png" alt="">

      <div class="flex mt-4 items-center justify-between">
        <div class="flex items-center">
          <input type="checkbox" id="checked" v-model="checked">
          <label for="checked" class="cursor-pointer flex text-primary text-xs items-center"> Don’t show this again</label>
        </div>
        <Button size="small" :onClick="close" classname="pr-1.5">
          <span>Got it</span>
          <i class="twa twa-thumbsup"/>
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'
import { setExportMessageDontAsk } from '@/components/common/browserStorageUtility'

export default {
  components: {
    Modal,
    Button
  },
  data: function() {
    return {
      checked: false
    }
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      closeModal: 'dialogs/closeModal'
    }),
    close () {
      if (this.checked) {
        setExportMessageDontAsk(this.checked)
      }

      this.closeModal()
    }
  }
}
</script>
<style lang="less" scoped>
.root {
  width: 560px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  padding-left: 30px;
}

label::before {
  top: -1px !important;
}

label:after {
  top: 8px !important;
}
</style>
